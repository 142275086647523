<template>
  <v-container class="p-4 px-1">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 mt-2 mb-2">
        Abastecimiento - Reporte de Productos
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center">
      <v-col cols="12" md="4" sm="12" class="">
        <v-text-field
          label="Busqueda de producto"
          class="my-0"
          v-model="filters.filter"
          @input="getReportProductsData(1)"
          clearable
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12"></v-col>
      <v-col cols="12" md="4" sm="12" class="">
        <v-btn
          class="float-right mt-1 mx-3"
          color="primary text-white"
          small
          @click="downloadExcelReportProducts"
        >
          <v-icon small class="mr-1">
            mdi-export
          </v-icon>
          Exportar
        </v-btn>
        <v-btn
          class="float-right mt-1"
          color="success text-white"
          small
          @click="updateReportProducts"
        >
          <v-icon small class="mr-1">
            mdi-update
          </v-icon>
          Sincronizar Datos Reporte
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :key="keyTable"
      :headers="tableHeaders"
      :items="
        reportProducts.material_report ? reportProducts.material_report : []
      "
      :page.sync="filters.page"
      :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
      item-key="id"
      :show-select="false"
      :loading="loadingMaterials"
      class="elevation-3 mt-2 pt-2"
      loading-text="Cargando reporte"
      hide-default-footer
      no-data-text="Sin productos actualmente"
    >
      <template v-slot:item.Grupos="{ item }">
        <p class="my-0 text-table">
          {{ item.Grupos }}
        </p>
      </template>
      <template v-slot:item.Familia="{ item }">
        <p class="my-0 text-table">
          {{ item.Familia }}
        </p>
      </template>
      <template v-slot:item.Código="{ item }">
        <p class="my-0 text-table">
          {{ item.Código }}
        </p>
      </template>
      <template v-slot:item.Descripción="{ item }">
        <p class="my-0 text-table text-left">
          {{ item.Descripción }}
        </p>
      </template>
      <template v-slot:item.Unidad="{ item }">
        <p class="my-0 text-table">
          {{ item.Unidad }}
        </p>
      </template>
      <template v-slot:item.Grupo="{ item }">
        <p class="my-0 text-table">
          {{ item.Grupo }}
        </p>
      </template>
      <template v-slot:item.ItemGasto="{ item }">
        <p class="my-0 text-table">
          {{ item.ItemGasto }}
        </p>
      </template>
      <template v-slot:item.Precio="{ item }">
        <p class="my-0 text-table text-right">
          {{
            item.Precio !== null
              ? item.Precio.toLocaleString("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })
              : "-"
          }}
        </p>
      </template>
      <template v-slot:item.Ultima_Compra="{ item }">
        <p class="my-0 text-table text-right">
          {{
            item.Ultima_Compra !== null
              ? item.Ultima_Compra.toLocaleString("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })
              : "-"
          }}
        </p>
      </template>
      <template v-slot:item.Costo_Referencia="{ item }">
        <p class="my-0 text-table text-right">
          {{
            item.Costo_Referencia !== null
              ? item.Costo_Referencia.toLocaleString("es-CL", {
                  style: "currency",
                  currency: "CLP",
                })
              : "-"
          }}
        </p>
      </template>
      <template v-slot:item.Activo="{ item }">
        <p class="my-0 text-table">
          {{ item.Activo == "Y" ? "Si" : "No" }}
        </p>
      </template>
    </v-data-table>
    <div class="d-flex justify-content-end pt-3">
      <v-pagination
        :total-visible="8"
        v-model="filters.page"
        :length="reportProducts.total_pages ? reportProducts.total_pages : 1"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import swal2 from "sweetalert2";
export default {
  mounted() {
    this.getReportProductsData();
  },
  data() {
    return {
      loading: false,
      loadingMaterials: false,
      keyTable: null,
      debounce: null,
      debounceTime: 500,
      filters: {
        page: 1,
        filter: "",
        items: 20,
      },
      tableHeaders: [
        {
          text: "Grupo",
          align: "center",
          sortable: false,
          value: "Grupo",
          width: "15%",
        },
        {
          text: "Familia",
          align: "center",
          sortable: false,
          value: "Familia",
          width: "20%",
        },
        {
          text: "Item de Gasto",
          align: "center",
          sortable: false,
          value: "ItemGasto",
        },
        {
          text: "Código",
          align: "center",
          sortable: false,
          value: "Código",
          width: "10%",
        },
        {
          text: "Descripción",
          align: "center",
          sortable: false,
          value: "Descripción",
        },
        {
          text: "Costo Promedio",
          align: "center",
          sortable: false,
          value: "Precio",
        },
        {
          text: "Última Compra",
          align: "center",
          sortable: false,
          value: "Ultima_Compra",
        },
        {
          text: "Costo Referencia",
          align: "center",
          sortable: false,
          value: "Costo_Referencia",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          value: "Unidad",
        },
        {
          text: "Activo",
          align: "center",
          sortable: false,
          value: "Activo",
        },
      ],
      breadcrumb: [
        {
          text: "Abastecimiento",
          disabled: false,
          href: "/supplying",
        },
        {
          text: "Reporte de Productos",
          disabled: true,
          href: "/supplying/reports/products",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getReportProducts: "supplying/getReportProducts",
      sendDownloadExcelReportProducts: "supplying/downloadExcelReportProducts",
      sendUpdateReportProducts: "supplying/updateReportProducts",
    }),
    async getReportProductsData(page = 1) {
      let self = this;
      clearTimeout(self.debounce);
      self.debounce = setTimeout(async function() {
        self.loadingMaterials = true;
        await self.getReportProducts({
          pages: page - 1,
          items: self.filters.items,
          material_filter: self.filters.filter
            ? self.filters.filter
            : undefined,
        });
        self.loadingMaterials = false;
      }, self.debounceTime);
    },
    async updateReportProducts() {
      this.loading = true;
      const response = await this.sendUpdateReportProducts();
      if (response.status == 200) {
        await this.getReportProductsData();
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Reporte actualizado correctamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 6000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
    },
    async downloadExcelReportProducts() {
      this.loading = true;
      const response = await this.sendDownloadExcelReportProducts({
        material_filter: this.filters.filter ? this.filters.filter : undefined,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `reporte_productos_abastecimiento.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      reportProducts: "supplying/reportProducts",
    }),
  },
  watch: {
    "filters.page": function(page) {
      this.getReportProductsData(page);
    },
  },
};
</script>

<style>
.text-table {
  font-size: 13px;
}
</style>
